import React, { ReactElement } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, Row, Col } from 'react-bootstrap';

import { SecureChangePassword } from 'types/change-password';
import { SECURE_CHANGE_PASSWORD_SCHEMA } from 'schema/change-password';

import Button from 'ui-kit/button/button';
import PasswordRules from 'components/password-rules/password-rules.component';
import PasswordText from 'ui-kit/password-text/password-text';
import { SecureChangePasswordProps } from 'components/change-password-form/change-password-form.props';

const SecureChangePasswordForm = ({ onSubmit, style }: SecureChangePasswordProps): ReactElement => {
    const { t } = useTranslation();
    const formName = 'SecureChangePasswordForm';

    const handleSubmit = (values: Partial<SecureChangePassword>) => {
        onSubmit(values as SecureChangePassword);
    };
    return (
        <Container fluid style={style}>
            <Row>
                <Col className="d-flex flex-column">
                    <p className="profile-form-instructions">{t('pages.profile.security.instructions')}</p>
                    <Formik<Partial<SecureChangePassword>>
                        onSubmit={handleSubmit}
                        validationSchema={SECURE_CHANGE_PASSWORD_SCHEMA}
                        initialValues={{
                            currentPassword: undefined,
                            newPassword: undefined,
                            newPasswordConfirm: undefined
                        }}
                    >
                        {(formik: any) => (
                            <Form
                                id="password-change-form"
                                data-ga-form-name={formName}
                                onSubmit={formik.handleSubmit}
                                autoComplete="off"
                            >
                                <Row>
                                    <Col>
                                        <PasswordText
                                            name="currentPassword"
                                            label={t('pages.profile.security.labels.currentPassword')}
                                            type="password"
                                            onChange={formik.handleChange}
                                            touched={formik.touched.currentPassword}
                                            value={formik.values?.currentPassword}
                                            errors={
                                                formik.errors?.currentPassword
                                                    ? t(`pages.profile.security.errors.currentPassword`)
                                                    : undefined
                                            }
                                            showErrorsText={true}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <PasswordText
                                            name="newPassword"
                                            label={t('pages.profile.security.labels.newPassword')}
                                            type="password"
                                            onChange={formik.handleChange}
                                            touched={formik.touched.newPassword}
                                            value={formik.values?.newPassword}
                                            errors={formik.errors?.newPassword}
                                        />
                                        <PasswordRules
                                            errors={formik.errors?.newPassword}
                                            touched={formik.touched.newPassword}
                                            password={formik.values?.newPassword}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <PasswordText
                                            name="newPasswordConfirm"
                                            label={t('pages.profile.security.labels.newPasswordConfirm')}
                                            type="password"
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.newPassword || formik.errors?.newPasswordConfirm
                                                    ? t(`registration.errors.passwordConfirm`)
                                                    : undefined
                                            }
                                            touched={formik.touched.newPasswordConfirm || formik.touched.newPassword}
                                            value={formik.values?.newPasswordConfirm}
                                            showErrorsText={true}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button
                                            className="sm-full"
                                            label={t('pages.profile.security.button')}
                                            disabled={!formik.dirty || (formik.dirty && !formik.isValid)}
                                            variant="primary"
                                            type="submit"
                                            dataGAFormName={formName}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    );
};

export default SecureChangePasswordForm;
